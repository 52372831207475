import React, { useEffect } from 'react';
import { appUrl } from '../common/constants';

const AppPage = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.location.replace(appUrl);
        }
    }, []);

    return null;
};

export default AppPage;